@tailwind base;
@tailwind screens;
@tailwind utilities;
@tailwind components;

body {
  font-family: 'Roboto Regular', Roboto, 'Helvetica Neue', Arial, sans-serif;
  background-color: rgb(39, 39, 42);
}

button,
h1,
h2,
h3,
h4,
h5 {
  font-family: 'Roboto Medium', Roboto, 'Helvetica Neue', Arial, sans-serif;
}

p {
  font-family: 'Roboto Light', Roboto, 'Helvetica Neue', Arial, sans-serif;
}
